import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { TableCellAction } from '../table/TableCellEditButton'
import { Link } from 'react-router-dom'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { Cell } from '../table/TableCellText.d'
import typeList from './TypeList'
import { debounceSearch } from '../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const TableCellManageIsSubField: React.FC<Cell> = (props) => {
    const value = props.value || ''

    return (
        <>
            {value === true ? <span className="badge bg-primary text-white">Sub Field</span> : ''}
        </>
    )
}

const TableCellManageIsConditional: React.FC<Cell> = (props) => {
    const value = props.value || ''

    return (
        <>
            {value === true ? <span className="badge bg-success text-white">Conditional</span> : ''}
        </>
    )
}

const TableCellManageIsGlobal: React.FC<Cell> = (props) => {
    const value = props.value || ''

    return (
        <>
            {value === true ? <span className="badge bg-info">Global</span> : ''}
        </>
    )
}

const FieldsPage: React.FC<Props> = ({ rootStore }) => {
    const { fieldStore, allTemplatesStore, setTitle } = rootStore
    const { allTemplatesName } = allTemplatesStore
    const { isApiError, isLoading, apiErrorMessage, searchItem, fieldData, fetchField, searchByTemplateFilter, isGlobal, isConditional, isSubField, isHidden, searchByTypeFilter, groupFieldsValueLabel, searchByGroupField, getGroupFieldList, isPageOpened } = fieldStore

    const [searchItemField, setSearchItemField] = useState(searchItem)

    const templateFilterValue = allTemplatesName.find((item) => item._id === searchByTemplateFilter)
    const [searchFromTemplate, setSearchFromTemplate] = useState((templateFilterValue === undefined) ? { value: 'All', label: 'All' } : { value: templateFilterValue._id, label: templateFilterValue.name })
    const typeFilterValue = typeList.find((item) => item === searchByTypeFilter)
    const [searchFromType, setSearchFromType] = useState((typeFilterValue === undefined) ? { value: 'All', label: 'All' } : { value: typeFilterValue, label: typeFilterValue })

    const [isGlobalField, setIsGlobal] = useState(isGlobal)
    const [isConditionalField, setIsConditional] = useState(isConditional)
    const [isSubFieldValue, setIsSubField] = useState(isSubField)
    const [isHiddenValue, setIsHiddenValue] = useState(isHidden)
    const groupFilteredValue = groupFieldsValueLabel.find((item) => item.value === searchByGroupField)
    const [groupField, setGroupField] = useState((groupFilteredValue === undefined) ? { value: 'All', label: 'All' } : groupFilteredValue)

    const loadPage = (page: number) => {
        fetchField(page, searchItem, 'change_page', searchFromTemplate.value, isGlobal, isConditional, isSubField, isHidden, searchFromType.value, groupField.value)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchField(1, e.target.value, 'search_item', searchFromTemplate.value, isGlobal, isConditional, isSubField, isHidden, searchFromType.value, groupField.value))
    }

    const handleOnChangeTemplate = (data: any) => {
        if (data !== null) {
            setSearchFromTemplate(data)
            fetchField(1, searchItem, 'change_filter', data.value, isGlobal, isConditional, isSubField, isHidden, searchFromType.value, groupField.value)
        }
    }

    const handleOnChangeType = (data: any) => {
        if (data !== null) {

            setSearchFromType(data)
            if (data.value !== 'group') {
                setGroupField({ value: 'All', label: 'All' })
            }
            fetchField(1, searchItem, 'change_filter', searchFromTemplate.value, isGlobal, isConditional, isSubField, isHidden, data.value, groupField.value)
        }
    }

    const handleIsGlobal = (e: any) => {
        const value = e.target.checked
        setIsGlobal(value)
        fetchField(1, searchItem, 'change_filter', searchFromTemplate.value, value, isConditional, isSubField, isHidden, searchFromType.value, groupField.value)
    }

    const handleIsConditional = (e: any) => {
        const value = e.target.checked
        setIsConditional(value)
        fetchField(1, searchItem, 'change_filter', searchFromTemplate.value, isGlobal, value, isSubField, isHidden, searchFromType.value, groupField.value)
    }

    const handleIsSubField = (e: any) => {
        const value = e.target.checked
        setIsSubField(value)
        fetchField(1, searchItem, 'change_filter', searchFromTemplate.value, isGlobal, isConditional, value, isHidden, searchFromType.value, groupField.value)
    }

    const handleIsHidden = (e: any) => {
        const value = e.target.checked
        setIsHiddenValue(value)
        fetchField(1, searchItem, 'change_filter', searchFromTemplate.value, isGlobal, isConditional, isSubField, value, searchFromType.value, groupField.value)
    }

    const handleChangeGroupField = (data: any) => {
        if (data !== null) {
            setGroupField(data)
            fetchField(1, searchItem, 'change_filter', searchFromTemplate.value, isGlobal, isConditional, isSubField, isHidden, searchFromType.value, data.value)
        }
    }

    const option = typeList.map((list: any) => {
        const data = {
            value: list || '',
            label: list || ''
        }
        return data
    })
    option.splice(0, 0, { value: 'All', label: 'All' })
    const templateValueLabel = allTemplatesName.map((template) => {
        return {
            value: template._id,
            label: template.name
        }
    })
    templateValueLabel.splice(0, 0, { value: 'All', label: 'All' })

    useEffect(() => {
        allTemplatesStore.getAllTemplatesName()
        setTitle('Fields | OathZephyr')        
        if(rootStore.authStore.isAdmin && !isPageOpened){
            fetchField(1, '', '', 'All', false, false, false, false, 'All', 'All')
            getGroupFieldList('All', false, false, false, false)
        }
    }, [])

  
    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
          Fields
                    <Link to='/fields/add' className='ml-auto px-3'>Add Field</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='custom-input-row'>
                            <div>
                                <label className='col-auto pl-0 pr-0'>Template:</label>
                                <div>
                                    <ReactSelectComponent
                                        isSearchable={true}
                                        options={templateValueLabel}
                                        value={searchFromTemplate}
                                        handleOnChangeReact={handleOnChangeTemplate}
                                    />
                                </div>
                            </div>
                            <div>
                                <label className='col-auto pl-0 pr-0'>Type:</label>
                                <div>
                                    <ReactSelectComponent
                                        isSearchable={true}
                                        options={option}
                                        value={searchFromType}
                                        handleOnChangeReact={handleOnChangeType}
                                    />
                                </div>
                            </div>
                            <div>
                                {searchFromType.value === 'group' ? <><label className='col-auto pr-0 pl-0'>Filter by Group:</label>
                                    <div>
                                        <ReactSelectComponent
                                            isSearchable={true}
                                            options={groupFieldsValueLabel}
                                            value={groupField}
                                            handleOnChangeReact={handleChangeGroupField}
                                        />
                                    </div></> : null}
                            </div>
                        </div>
                        <div>
                        </div>
                        <div className="mt-4 custom-search-checkboxes">
                            <div className=''>
                                <div className='col-auto mr-4 pr-0 custom-switch'>
                                    <input type="checkbox" className="custom-control-input" name='is_sub_field' id="isSubField" onClick={handleIsSubField} defaultChecked={isSubFieldValue} />
                                    <label className="custom-control-label pr-2" htmlFor='isSubField'>Is Sub Field?</label>
                                </div>
                                <div className='col-auto mr-4 pr-0 custom-switch'>
                                    <input type="checkbox" className="custom-control-input" name='is_conditional' onClick={handleIsConditional} defaultChecked={isConditionalField} id="isConditional" />
                                    <label className="custom-control-label pr-2" htmlFor='isConditional'>Is Conditional?</label>
                                </div>
                                <div className='col-auto mr-4 pr-0 custom-switch'>
                                    <input type="checkbox" className="custom-control-input" name='is_global' onClick={handleIsGlobal} defaultChecked={isGlobalField} id="isGlobal" />
                                    <label className="custom-control-label pr-2" htmlFor='isGlobal'>Is Global?</label>
                                </div>
                                <div className='col-auto mr-4 pr-0 custom-switch'>
                                    <input type="checkbox" className="custom-control-input" name='is_hidden' id="isHidden" onClick={handleIsHidden} defaultChecked={isHiddenValue} />
                                    <label className="custom-control-label pr-2" htmlFor='isHidden'>Is Hidden?</label>
                                </div>
                            </div>
                            <div className=''>
                                <input className="form-control" name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>


                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'name', title: 'Name' },
                                    { name: 'type', title: 'Type' },
                                    { name: 'variable_name', title: 'Varible Name' },
                                    { name: 'is_sub_field', title: 'Is Sub Field', component: TableCellManageIsSubField },
                                    { name: 'is_conditional', title: 'Is Conditional', component: TableCellManageIsConditional },
                                    { name: 'is_global', title: 'Is Global', component: TableCellManageIsGlobal },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={fieldData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={fieldData.currentPage}
                            totalPages={fieldData.totalPage}
                            totalItems={fieldData.totalItem}
                            itemsPerPage={fieldData.itemPerPage}
                            isFiltered={fieldData.isFiltered}
                            totalAllItems={fieldData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(FieldsPage)
